import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ArrowCTA from '../assets/svg/arrow_cta.svg'

const SuccessPage = () => {
  return(
    <Layout>
      <SEO title="Thanks!"/>
      <div className="privacy-notfound">
        <div className="success-container">
          <h1 className='title'>Your form has been sent correctly</h1>
          <p className='main-text'>We will contact you as soon as possible.</p>
          <Link className="cta" to="/stories">Check out our Stories <ArrowCTA/></Link>
        </div>
      </div>
    </Layout>
  )
}

export default SuccessPage
